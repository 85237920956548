.welcomeBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcomeTextBlock {
  display: flex;
  justify-content: center;
}

@media (max-width: 499px) {
  .welcomeTitle {
    font-family: montserrat;
    font-size: 3.2rem;
    margin-top: 5px;
  }
}
.welcomeLogo {
  width: 81px;
}

@media (min-width: 500px) {
  .welcomeBanner {
    flex-direction: row;
  }
  .welcomeTitle {
    font-family: montserrat;
    font-size: 4rem;
  }
}
