/* EdwardML title styling */

.welcomeBanner {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Registration form styling */

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: 1px solid;
  border-radius: 11px;
  padding: 30px 50px 30px 50px;
  max-width: 400px;
}

@media (max-width: 450px) {
  .register__container {
    border: none;
    padding-top: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.emailForm {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.MuiButton-root {
  font-family: interExtraBold;
}

.register__btn {
  height: 48px;
  max-width: 350px;
}

input {
  font-family: interMedium;
  font-size: 1rem;
  border-radius: 9px;
  border: 1px #d9d9d9 solid;
  padding: 10px;
  margin-bottom: 24px;
  max-width: 350px;
}

.MuiOutlinedInput-root {
  font-family: interMedium;
}

.MuiInputBase-input {
  height: 60px;
}

.MuiOutlinedInput-input {
  height: 60px;
}
