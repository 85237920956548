form {
  display: flex;
  justify-content: center;
}

list,
.profile {
  text-align: center;
}

li {
  width: 300 px;
}

.leftH3 {
  font-family: interExtraBold, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  text-align: left;
}

.css-10hbu.MuiListItemText-primary {
  font-family: interSemiBold, Arial, Helvetica, sans-serif;
}

.top8titles {
  margin: 0px;
  padding: 0px;
}

.top8titles li {
  display: flex;
  align-items: center;
  margin: 0.2em 5em 0.2em 0rem;
  padding-left: 2rem;
}
