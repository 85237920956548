/* HEADER STYLING */

.header {
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.logo {
  display: flex;
  align-items: center;
}
.appName {
  text-decoration: none;
  font-size: 1.5rem;
  font-family: "montserrat";
}
