body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 50px;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
